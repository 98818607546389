<template>
  <div id="venueVehicle">
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getList(1)"
              ></el-button>
            </el-input>
          </li>
          <li>
            <span class="label">分组:</span>
            <el-select
              v-model="filtrate.seatGroupId"
              placeholder="请选择分组"
              filterable
              clearable
              @change="getList(1)"
            >
              <el-option
                v-for="item of seatGroupList"
                :key="item.seatGroupId"
                :label="item.seatGroupName"
                :value="item.seatGroupId"
              ></el-option>
            </el-select>
          </li>
          <li>
            <span class="label">记录类型:</span>
            <el-select
              v-model="filtrate.arriveType"
              placeholder="请选择记录类型"
              filterable
              clearable
              @change="getList(1)"
            >
              <el-option label="系统补录" value="系统补录"></el-option>
              <el-option label="设备识别" value="设备识别"></el-option>
            </el-select>
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置
            </el-button>
          </li>
        </ul>
        <ul>
          <li>
            <el-button
              class="create_btn"
              type="primary"
              @click="batchForcedOut"
              :loading="batchLoading"
              v-if="
                buttonList.includes(
                  'parkingLotList-detail-venueVehicleTab-batchForcedOut'
                )
              "
              >批量出场</el-button
            >
          </li>
          <li>
            <el-button
              class="create_btn"
              type="primary"
              @click="exportExcel"
              v-if="
                buttonList.includes(
                  'parkingLotList-detail-venueVehicleTab-export'
                )
              "
              :loading="exportLoading"
              ><i class="iconfont el-icon-download"></i>导出报表
            </el-button>
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
        @selection-change="selectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
          v-if="
            buttonList.includes(
              'parkingLotList-detail-venueVehicleTab-batchForcedOut'
            )
          "
        >
        </el-table-column>
        <el-table-column label="车牌号" align="center">
          <template #default="scope">
            <span class="plateNum" @click="goToPage(scope.row.plateNum)">{{
              scope.row.plateNum
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="vehicleType" label="车辆类型" align="center">
        </el-table-column>
        <el-table-column prop="seatGroupName" label="分组" align="center">
        </el-table-column>
        <el-table-column prop="channelName" label="入场设备" align="center">
        </el-table-column>
        <el-table-column prop="arriveType" label="记录类型" align="center">
        </el-table-column>
        <el-table-column prop="arrivalTime" label="入场时间" align="center">
        </el-table-column>
        <el-table-column prop="parkingTime" label="停车时长" align="center">
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="forcedOut(scope.row.parkingRecordId)"
              v-if="
                buttonList.includes(
                  'parkingLotList-detail-venueVehicleTab-forcedOut'
                )
              "
              >强制出场
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, prev, pager, next,jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @size-change="getList(1)"
          @current-change="getList"
        >
        </el-pagination>
      </div>
    </div>
    <batchResultDialog ref="batchResultDialog" />
  </div>
</template>
<script>
export default {
  components: {
    batchResultDialog: () => import("./batchResultDialog.vue"),
  },
  data() {
    return {
      filtrate: {
        keyword: "",
        seatGroupId: "",
        arriveType: "",
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
        selection: [],
      },
      batchLoading: false,
      seatGroupList: [],
      exportLoading: false,
    };
  },
  created() {
    this.getList();
    this.getSeatGroupList();
  },
  methods: {
    async exportExcel() {
      try {
        this.exportLoading = true;
        await this.$http.post(
          "/parking/export/vehicle",
          {
            seatGroupId: this.filtrate.seatGroupId
              ? this.filtrate.seatGroupId
              : null,
            arriveType: this.filtrate.arriveType
              ? this.filtrate.arriveType
              : null,
            parkingLotId: this.$route.params.id,
          },
          {
            responseType: "blob",
            timeout: 0,
          }
        );
      } catch (err) {
        this.$message.error("导出失败");
      } finally {
        this.exportLoading = false;
      }
    },
    async getSeatGroupList() {
      let res = await this.$http.get(
        `/parking/seat/group/${this.$route.params.id}/all`
      );
      this.seatGroupList = res.data;
    },
    batchForcedOut() {
      const selection = this.table.selection;
      if (selection.length === 0) {
        this.$message.warning("请勾选需要操作的车辆！");
        return;
      }
      this.$confirm("此操作将不可撤销, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        this.batchLoading = true;
        this.$refs.batchResultDialog.show = true;
        this.$refs.batchResultDialog.loading = true;
        for (let item of selection) {
          const res = await this.batchForcedOutChange(item.parkingRecordId);
          if (res) {
            this.$refs.batchResultDialog.successList.push(item);
          } else {
            this.$refs.batchResultDialog.errorList.push(item);
          }
        }
        this.$message.success("执行已完成");
        this.$refs.batchResultDialog.loading = false;
        this.batchLoading = false;
        this.getList(1);
      });
    },
    batchForcedOutChange(parkingRecordId) {
      return new Promise((resolve, reject) => {
        setTimeout(async () => {
          try {
            let res = await this.$http.post(`/parking/forced/out`, {
              parkingRecordId,
            });
            if (res.code === 0) {
              resolve(true);
            } else {
              resolve(false);
            }
          } catch {
            resolve(false);
          }
        }, 100);
      });
    },
    selectionChange(selection) {
      this.table.selection = selection;
    },
    goToPage(plateNum) {
      this.$router.push({
        name: "parkingRecord",
        params: {
          plateNum,
        },
      });
    },
    forcedOut(parkingRecordId) {
      this.$confirm("此操作将强制出场, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let res = await this.$http.post(`/parking/forced/out`, {
          parkingRecordId,
        });
        if (res.code === 0) {
          this.$message.success("操作成功");
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    reset() {
      this.filtrate.keyword = "";
      this.filtrate.seatGroupId = "";
      this.filtrate.arriveType = "";
      this.getList(1);
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/parking/list/vehicle", {
          keyword: this.filtrate.keyword,
          seatGroupId: this.filtrate.seatGroupId
            ? this.filtrate.seatGroupId
            : null,
          arriveType: this.filtrate.arriveType
            ? this.filtrate.arriveType
            : null,
          pageNum: page,
          pageSize: this.table.pageSize,
          parkingLotId: this.$route.params.id,
        });
        if (res.code === 0) {
          for (let item of res.data.list) {
            item.arrivalTime = this.$moment(item.arrivalTime).format(
              "yyyy-MM-DD HH:mm:ss"
            );
            item.plateNum = item.plateNum ?? "无牌车";
          }
          Object.assign(this.table, {
            tableData: res.data.list,
            selection: [],
            total: res.data.total,
          });
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#venueVehicle {
  .plateNum {
    cursor: pointer;
  }
}
</style>
